import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"


export default function Terms () {

    return (
      <Layout navbar="transparent">
        <Head title="Data Processing Agreement" description="Data Processing Agreement"/>
         
        <BackgroundShape style={{shape: "clip-fixed-small", color: "bg-data-sm", particles: false}}/>
        <Container style={{classes: "container" }}>
          <div>
            <Header content={{title: "Data Processing Agreement", text: ""}} style={{color: "text-center"}}/>
          </div>
        </Container>
        <Container style={{classes: "container terms mb-5" }}>
        <p>
        Explore the details of our Data Processing Agreement (DPA) – an integral component of Onport's online Terms & Conditions governing the relationship between Onport and our valued customers. Click <a href='https://eform.pandadoc.com/?eform=2125ae8e-fbfb-4757-aa6c-d60887e1ec2d' target="_blank">here</a> to thoroughly review and electronically sign the document.
        </p>
        <p>
        Please note that for customers who have already signed a Master Services Agreement, this particular DPA does not apply, as a dedicated and specific DPA has already been executed as part of the previous agreement.
        </p>
        </Container>
      </Layout>
    )
}
